button.bg-red
  transition: .3s
  &:hover
    background-color: $primary-hover !important

a.btn.bg-red
  transition: .3s
  &:hover
    background-color: $light-primary !important

button.bg-tertiary-blue
  border: none
  transition: .3s
  &:hover
    background-color: #74e3f1 !important