//helper class
.mr-3
  padding-right: 12px   
.pa-4 
  padding: 16px
.pa-5 
  padding: 20px
.mt-5 
  margin-top:20px
.border-none
  border: none
.btn-block
  display: block
  width: 100%
.h-100
  height: 100%


// Colors Class text
.text-red
  color: $tertiary-text     
.text-white
  color: $white
.primary-text
  color: $primary-text
.text-black
  color: $black
.text-orange
  color: $secondary-color       
// Colors Class Backgrounds
.bg-gray
  background: #eef1f5
.bg-lightred
  background: $light-primary 
.bg-red
  background: $primary-color 
.bg-darkred
  background: $primary-hover 
.bg-white
  background: $white
.bg-light
  background: $bg-light !important
.bg-tertiary-blue
  background-color: $tertiary-blue