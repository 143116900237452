.sidebar 
  border-top-right-radius: 25px
  border-bottom-right-radius: 25px
  ul 
    padding-top: 50px
    a
      color: $primary-text !important

.sidebar-brand
  background: transparent

.sidebar-toggler
  background-color: transparent

.sidebar-toggler:hover
  background-color: #00bcd4
  color: $tertiary-text !important
  border-bottom-right-radius: 25px