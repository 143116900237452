// Colors
$kefuri-primary: #dd4747
$kefuri-primary-light: #f55a5a
$kefuri-secondary: #00bcd4
$kefuri-secondary-light: #12cee7

$light-primary: #e57272
$primary-color: #dd4747
$primary-hover: #d93131
$disabled: #e8e9ea

$secondary-color: #e8764f
$secondary-hover: #cf491b

$tertiary-green: #31d985
$tertiary-blue: #00bcd4
$light-bg: #ecf4fc

$primary-text: #363636
$secondary-text: #707071
$tertiary-text: #e83b3b
$white: #fff
$black: #000

$bg-light: #EAEDF8
$light-red: #ed6d63

