// Here you can add other styles
body {
    font-family:'nunito', sans-serif;
}

.wrapper {
    background-color: $bg-light;
}
@keyframes link-animation {
    from {opacity: 0.4 ;}
    to {opacity: 1 ;}
}
a{
    text-decoration: none;
    color: $black;
}
.sidebar-nav .nav-link {
    margin-left: 20px;
    position: relative;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    font-weight: 500;
}
.sidebar-nav .nav-link i {
    margin-right: 15px;
}

.sidebar-nav .nav-link.active {
    background: $bg-light;
    animation-name: link-animation;
    animation-duration: 0.5s;
    color: $tertiary-text !important;

}
.sidebar-nav .nav-group-toggle::after {
    background-color: $light-red;
    border-radius: 25px;
    padding: 8px;
}
.sidebar-nav .nav-link.active span::after {
    content: '';
    background-color: $white;
    height: 10px;
    width: 10px;
    position: absolute;
    bottom: -10px;
    right: 0;
    border-top-right-radius: 10px;
    z-index: 2;
}
.sidebar-nav .nav-link.active span::before {
    content: '';
    background-color: $white;
    height: 10px;
    width: 10px;
    position: absolute;
    top: -10px;
    right: 0;
    border-bottom-right-radius: 10px;
    z-index: 2;
}

.sidebar-nav .nav-group.show {
    background-color: transparent;
}

.sidebar-header {
    background-color:transparent;
}

.sidebar-nav .nav-link .nav-icon {
    color: $light-red;
    transition: width 2s;
}
.sidebar-nav .nav-link.active .nav-icon {
    color: $light-red;
}
.sidebar-nav .nav-link:hover .nav-icon {
    color: $light-red !important;
    height: 24px;
}

.header {
    background: transparent;
    border-bottom: 0px;
}

.bg-login{
    background-image: url('https://i.imgur.com/otAwD32.jpg');
    background-position: center;
}
.bg-welcome{
    background-image: url('https://kefuri.cl/img/bg-appccyan.901b267e.jpg');
    background-position: center;
    background-size: cover;
}

.bg-kefuri {
    background-color: $kefuri-primary;
}


//Buttons

.kefuri-btn-1 {
    background-color: $kefuri-primary;
    color: white;
    border: 0px;
}
.kefuri-btn-1:hover {
    background-color: $kefuri-primary-light;
    color: white;
}

.kefuri-btn-2 {
    background-color: $kefuri-secondary;
    color: white;
}
.kefuri-btn-2:hover {
    background-color: $kefuri-secondary-light;
    color: white;
}

//Edición de CARDS
.card {
    border-radius: 10px;
}
.card-header {
    background-color: transparent;
    padding-top: 1.2rem;
    border-bottom: none;
    font-size: 1.2rem;
    font-family: 'nunito', sans-serif;
}

.card-form {
    background: $bg-light;
    border: 0px;
    font-size: 1rem;
    font-weight: 500;

    input {
        margin-right: 16px;
    }
}
// Footer
.footer {
    background: transparent;
}
